import { Action, Reducer } from "redux";
import _ from "underscore";
import { ApiScanDatasetPurposeType, ApiView } from "avvir";

import CloudFile from "../../../models/domain/cloud_file";
import makeUpdateModel from "./make_update_model";
import PurposeTypeConverter from "../../converters/purpose_type_converter";
import ScanDataset from "../../../models/domain/scan_dataset";
import ScanDatasetConverter from "../../converters/scan_dataset_converter";
import { FLOOR_DELETED, FloorDeletedEvent } from "../../../events/superadmin/floors/floor_deleted";
import { PushedToBimtrackEvent } from "../../../events/viewer/pushed_to_bimtrack";
import { SCAN_DATASET_CREATED, ScanDatasetCreatedEvent } from "../../../events/superadmin/scan_datasets/scan_dataset_created";
import { SCAN_DATASET_DELETED, ScanDatasetDeletedEvent } from "../../../events/superadmin/scan_datasets/scan_dataset_deleted";
import { SCAN_DATASET_FILE_UPLOADED, ScanDatasetFileUploadedEvent } from "../../../events/uploaded/scan_dataset_file_uploaded";
import { SCAN_DATASET_FILES_FOR_PROJECT_LOADED, ScanDatasetFilesForProjectLoadedEvent } from "../../../events/loaded/scan_datasets/scan_dataset_files_for_project_loaded";
import { SCAN_DATASET_FILES_LOADED, ScanDatasetFilesLoadedEvent } from "../../../events/loaded/scan_datasets/scan_dataset_files_loaded";
import { SCAN_DATASET_LOADED, ScanDatasetLoadedEvent } from "../../../events/loaded/scan_datasets/scan_dataset_loaded";
import { SCAN_DATASET_STATS_LOADED, ScanDatasetStatsLoadedEvent } from "../../../events/loaded/scan_datasets/scan_dataset_stats_loaded";
import { SCAN_DATASET_UPDATED, ScanDatasetUpdatedEvent } from "../../../events/superadmin/scan_datasets/scan_dataset_updated";
import { SCAN_DATASETS_CREATED_FROM_TSV, ScanDatasetsCreatedFromTsvEvent } from "../../../events/superadmin/scan_datasets/scan_datasets_created_from_tsv";
import { SCAN_DATASETS_FOR_FLOOR_LOADED, ScanDatasetsForFloorLoadedEvent } from "../../../events/loaded/scan_datasets/scan_datasets_for_floor_loaded";
import { ScanDatasetStats } from "../../../models/domain/scan_dataset_stats";
import { ScanDatasetStatsConverter } from "../../converters/scan_dataset_stats_converter";
import { uploadHistoryPurposeTypes } from "../../../models/domain/enums/purpose_type";
import { VIEWS_LOADED, ViewsLoadedEvent } from "../../../events/loaded/views_loaded";
import { SCAN_DATASETS_FOR_PROJECT_LOADED, ScanDatasetsForProjectLoadedEvent } from "../../../events/loaded/scan_datasets/scan_datasets_for_project_loaded";

type ScanDatasetEvents =
  | ScanDatasetsForProjectLoadedEvent
  | ScanDatasetsForFloorLoadedEvent
  | ScanDatasetLoadedEvent
  | ScanDatasetFilesLoadedEvent
  | ScanDatasetFilesForProjectLoadedEvent
  | ScanDatasetFileUploadedEvent
  | ScanDatasetUpdatedEvent
  | ScanDatasetsCreatedFromTsvEvent
  | ScanDatasetDeletedEvent
  | ScanDatasetCreatedEvent
  | ScanDatasetStatsLoadedEvent
  | PushedToBimtrackEvent
  | FloorDeletedEvent
  | ViewsLoadedEvent
  | Action<"create_view_done">;

export interface ScanDatasetsStore {
  stats?: ScanDatasetStats[],
  byFirebaseId: {
    [firebaseId: string]: ScanDataset
  };
}

const emptyScanDataset = new ScanDataset();

const updateScanDataset = makeUpdateModel<ScanDataset>(emptyScanDataset);

const removeScanDataset = (scanDatasets: ScanDatasetsStore, scanDatasetId: string): ScanDatasetsStore => {
  const scanNumber = scanDatasets.byFirebaseId[scanDatasetId]?.scanNumber;
  const floorId = scanDatasets.byFirebaseId[scanDatasetId]?.firebaseFloorId;
  return {
    ...scanDatasets,
    byFirebaseId: _.chain(scanDatasets.byFirebaseId).mapObject((scanDataset: ScanDataset) => {
      if (scanDataset.firebaseFloorId === floorId && scanDataset.scanNumber > scanNumber) {
        return {
          ...scanDataset,
          scanNumber: scanDataset.scanNumber - 1
        };
      } else {
        return scanDataset;
      }
    }).omit(scanDatasetId).value()
  };
};

const reduceScanDatasets: Reducer<ScanDatasetsStore, ScanDatasetEvents> = (scanDatasets: ScanDatasetsStore = { byFirebaseId: {} }, event) => {
  if (!scanDatasets?.byFirebaseId) {
    scanDatasets = { byFirebaseId: {} };
  }
  switch (event?.type) {
    case SCAN_DATASETS_FOR_PROJECT_LOADED: {
      return event.payload.scanDatasets?.reduce((scanDatasetsSoFar, apiScanDataset) => {
        const scanDataset = ScanDatasetConverter.fromApi(apiScanDataset);
        return updateScanDataset(scanDatasetsSoFar, apiScanDataset.firebaseId, (originalScan) => {
          if (originalScan.scanDate?.getTime() === scanDataset.scanDate.getTime()) {
            return {
              ...originalScan,
              ...scanDataset,
              scanDate: originalScan.scanDate,
              files: {
                ...originalScan.files,
                ...scanDataset.files
              }
            };
          }
          return {
            ...originalScan,
            ...scanDataset,
            files: {
              ...originalScan.files,
              ...scanDataset.files
            }
          };
        });
      }, { ...scanDatasets });
    }
    case SCAN_DATASETS_FOR_FLOOR_LOADED: {
      return event.payload.scanDatasets?.reduce((scanDatasetsSoFar, apiScanDataset) => {
        const scanDataset = ScanDatasetConverter.fromApi(apiScanDataset);
        return updateScanDataset(scanDatasetsSoFar, apiScanDataset.firebaseId, (originalScan) => {
          if (originalScan.scanDate?.getTime() === scanDataset.scanDate.getTime()) {
            return {
              ...originalScan,
              ...scanDataset,
              scanDate: originalScan.scanDate,
              files: {
                ...originalScan.files,
                ...scanDataset.files
              }
            };
          }
          return {
            ...originalScan,
            ...scanDataset,
            files: {
              ...originalScan.files,
              ...scanDataset.files
            }
          };
        });
      }, { ...scanDatasets });
    }
    case SCAN_DATASET_LOADED: {
      return updateScanDataset(scanDatasets, event.payload.firebaseId, (originalScan) => {
        const newScan = ScanDatasetConverter.fromApi(event.payload);
        if (originalScan.scanDate?.getTime() === newScan.scanDate.getTime()) {
          return {
            ...newScan,
            scanDate: originalScan.scanDate,
            files: {
              ...originalScan.files
            }
          };
        }
        return {
          ...newScan,
          files: {
            ...originalScan.files
          }
        };
      });
    }
    case SCAN_DATASET_STATS_LOADED: {
      return {
        ...scanDatasets,
        stats: event.payload.map((apiScanDataset) => ScanDatasetStatsConverter.fromApi(apiScanDataset))
      };
    }
    case SCAN_DATASET_FILES_LOADED: {
      return updateScanDataset(scanDatasets, event.payload.scanDatasetId, (originalScan) => ({
        files: {
          ...originalScan.files,
          ...event.payload.files.reduce((newFilesSoFar, apiFile) => {
            const file = new CloudFile(apiFile);
            if (newFilesSoFar[file.purposeType]) {
              newFilesSoFar[file.purposeType] = _.union(newFilesSoFar[file.purposeType], [file.id]);
            } else {
              newFilesSoFar[file.purposeType] = [file.id];
            }
            return newFilesSoFar;
          }, {})
        }
      }));
    }
    case SCAN_DATASET_FILES_FOR_PROJECT_LOADED: {
      return _(event.payload).reduce<ScanDatasetsStore>((scanDatasetsSoFar, files, scanDatasetId) => {
        return updateScanDataset(scanDatasetsSoFar, scanDatasetId as string, (originalScan) => ({
          files: {
            ...originalScan.files,
            ...files.reduce((newFilesSoFar, apiFile) => {
              const file = new CloudFile(apiFile);
              if (newFilesSoFar[file.purposeType]) {
                newFilesSoFar[file.purposeType] = _.union(newFilesSoFar[file.purposeType], [file.id]);
              } else {
                newFilesSoFar[file.purposeType] = [file.id];
              }
              return newFilesSoFar;
            }, {})
          }
        }));
      }, scanDatasets);
    }
    case SCAN_DATASET_FILE_UPLOADED: {
      const purposeType = PurposeTypeConverter.toPurposeType(event.payload.file.purposeType as ApiScanDatasetPurposeType);
      const shouldAppendToFileUploads = uploadHistoryPurposeTypes.includes(purposeType)
                                        && scanDatasets.byFirebaseId[event.payload.scanDatasetId]?.files;
      const firebaseId = event.payload.scanDatasetId;
      return updateScanDataset(scanDatasets, firebaseId, (scanDataset) => {
        return {
          files: {
            ...scanDataset.files,
            [purposeType]: _.union((shouldAppendToFileUploads && scanDataset.files?.[purposeType]) || [], [event.payload.file.id])
          }
        };
      });
    }
    case SCAN_DATASET_UPDATED: {
      return updateScanDataset(scanDatasets, event.payload.firebaseId, (originalScan) => ({
        ...originalScan,
        ...ScanDatasetConverter.fromApi(event.payload),
        files: originalScan.files
      }));
    }
    case SCAN_DATASET_DELETED: {
      return removeScanDataset(scanDatasets, event.payload.scanDatasetId);
    }
    case SCAN_DATASET_CREATED: {
      return updateScanDataset(scanDatasets, event.payload.firebaseId, () => new ScanDataset(event.payload));
    }
    case SCAN_DATASETS_CREATED_FROM_TSV: {
      return event.payload.reduce((scanDatasetsSoFar, apiScanDataset) => {
        return updateScanDataset(scanDatasetsSoFar, apiScanDataset.firebaseId, () => ScanDatasetConverter.fromApi(apiScanDataset));
      }, scanDatasets);
    }
    case FLOOR_DELETED: {
      return removeScanDataset(scanDatasets, event.payload.scanDatasetId);
    }
    case VIEWS_LOADED: {
      if (event.payload[0]?.firebaseScanDatasetId) {
        return updateScanDataset(scanDatasets, event.payload[0].firebaseScanDatasetId, {
          views: event.payload
        });
      } else {
        return scanDatasets;
      }
    }
    case "create_view_done": {
      //@ts-ignore
      let view = event.payload as ApiView;
      return updateScanDataset(scanDatasets, view.firebaseScanDatasetId, (scanDataset) => ({
        ...scanDataset,
        views: [...(scanDataset.views || []), view]
      }));
    }
    default: {
      return scanDatasets;
    }
  }
};

export default reduceScanDatasets;
